import axios from "axios";
import { authenticate } from "./Endpoints";
const headers = {
  'Content-Type': 'application/json',
}
export default {
  
  async onLogin(data) {
    const response = await axios.post(authenticate.onLogin(), data,{
      headers:headers
    });
    return response.data;
  },
  async onRegister(data) {
    const response = await axios.post(authenticate.onRegesister(), data);
    return response.data;
  },
  async onMe(config) {
    const response = await axios.get(authenticate.onMe(), config);
    return response.data;
  },

  async onResetPassword(config) {
    const response = await axios.post(authenticate.onResetPassword(), config);
    return response.data;
  },
};
