// import axios from 'axios'
import React, { createContext, useReducer, useEffect } from 'react'
// import api from '../../Http/backend-base-url'
import { getData } from '../utils/fetchData'
import reducers from './Reducers'

export const DataContext = createContext()

export const DataProvider = ({ children }) => {

    const initialState = {
        auth: {},
    }

    const [state, dispatch] = useReducer(reducers, initialState)
    const {
        auth,
    } = state

    const token = window.localStorage.getItem('userToken');
    // const user_type = window.localStorage.getItem('userType');


    /*Recuperation auth user */
    useEffect(() => {
        if (token) {
            getData(`user/`, token)
                .then((res) => {
                    dispatch({
                        type: 'AUTH',
                        payload: res,
                    })
                })
                .catch((e) => {
                    console.log("error", e)
                })
        }
    }, [token])
    /*Recuperation auth user */

    /*Recuperation auth user */
    // useEffect(() => {
    //     if (token) {
    //         getData(`user/`, token)
    //             .then((res) => {
    //                 dispatch({
    //                     type: 'GET_ALL_USERS',
    //                     payload: res.results,
    //                 })
    //             })
    //             .catch((e) => {
    //                 console.log("error", e)
    //             })
    //     }
    // }, [token])
    /*Recuperation auth user */

    /*Recuperation project */
    // useEffect(() => {
    //     // if (token && user_type === "admin") {
    //     //Pour recuperer les project
    //     getData(`project/`, token)
    //     // getData(`candidatures/`, token)
    //         .then((res) => {
    //             dispatch({
    //                 type: 'CREATE_LIST_PROJECT',
    //                 payload: res.results,
    //             })
    //         })
    //         .catch((e) => {
    //             console.log("error", e)
    //         })
    //     //}
    //     // }, [token, user_type])
    // }, [token])

    // useEffect(() => {
    //     //Pour recuperer les project
    //     axios.get(`${api}projects/`)
    //         .then((res) => {
    //             dispatch({
    //                 type: 'LIST_PROJECT_FRONT',
    //                 payload: res.data.results.filter(x => x.is_visible === true),
    //             })
    //         })
    //         .catch((e) => {
    //             console.log("error", e)
    //         })
    // },[token,user_type])

    return (
        <DataContext.Provider value={{ state, dispatch }}>
            {children}
        </DataContext.Provider>
    )
}
