import React from "react";
import { Link } from "react-router-dom";
// import GoogleImg from "../../assets/images/google.svg";
import TckLogo from "../../assets/images/tck-logo.jpeg";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { useDispatch } from "react-redux";
import AuthServices from "../../services/AuthServices";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const SignIn = () => {

    // const dispatch = useDispatch();
    const history = useHistory();

    const [isSuccessfullySubmittedForm, setIsSuccessfullySubmittedForm] = React.useState(false);

    const validationSchema = yup.object().shape({
        username: yup.string().required("L'email est obligatoire"),
        password: yup
            .string()
            .required("le mot de passe est obligatoire")
    });

    const {
        register,
        handleSubmit,
        reset,
        formState,
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const { errors } = formState;

    const onSubmitLogin = async (data) => {

        setIsSuccessfullySubmittedForm(true);

        const request = AuthServices.onLogin(data);
        await request
            .then(async (resp) => {
                // await dispatch(fetchMe());
                window.localStorage.setItem("userToken", resp?.key);

                const config = {
                    headers: {
                        Authorization: `Token ${resp.key}`,
                    },
                };
                const requestMe = AuthServices.onMe(config);
                await requestMe
                    .then(async (res) => {
                        // console.log("resultats connexion", res)
                        await Swal.fire({
                            icon: "success",
                            title: "Connexion réussie!",
                            iconColor: '#068923',
                            showCancelButton: false,
                            confirmButtonColor: '#068923',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'OK',
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            timer: 3000,
                        })
                        window.localStorage.setItem("userID", res?.pk);
                        window.location.href='/hr-dashboard'
                    }).catch((err) => {
                        Swal.fire({
                            icon: "error",
                            title: "Connexion échouée!",
                            html: err?.response,
                            iconColor: '#068923',
                            showCancelButton: false,
                            confirmButtonColor: '#068923',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'OK',
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            timer: 3000,
                        })
                    })

            })
            .catch((error) => {
                if (error?.response?.status === 400) {
                    Swal.fire({
                        icon: "error",
                        title: "Impossible de se connecter avec les identifiants fournis.",
                        iconColor: '#068923',
                        showCancelButton: false,
                        confirmButtonColor: '#068923',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'OK',
                        showConfirmButton: true,
                        allowOutsideClick: false,
                        timer: 3000,
                    })
                }
            })
    }

    return (

        <div className="container">
            <div className="row g-0 my-5">
                <div className="col-lg-6 d-flex justify-content-center align-items-center border-0 rounded-lg ">
                    <div className="w-100 p-3 p-md-5 card border-0  text-light" style={{ maxWidth: "32rem", background: "#068923" }}>
                        <form onSubmit={handleSubmit(onSubmitLogin)} className="g-1 p-3 p-md-4">
                            <div className="text-center mb-1 mb-lg-5">
                                <h1>S'identifier</h1>
                                <span>Connectez-vous pour accéder au tableau de bord</span>
                            </div>
                            {/* <div className="col-12 text-center mb-4">
                                <a className="btn btn-lg btn-outline-secondary btn-block" href="#!">
                                    <span className="d-flex justify-content-center align-items-center">
                                        <img className="avatar xs me-2" src={GoogleImg} alt="Imag Description" />
                                        Connectez-vous avec Google
                                    </span>
                                </a>
                                <span className="dividers text-muted mt-4">OU</span>
                            </div> */}
                            <div className="col-12">
                                <div className="mb-2">
                                    <label className="form-label">Adresse e-mail</label>
                                    <input {...register('username')} type="text" className="form-control form-control-lg" placeholder="name@example.com" />
                                    {errors.username &&
                                        <div className="alert alert-danger sia-alert-danger closer">

                                            {errors.username?.message}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-2">
                                    <div className="form-label">
                                        <span className="d-flex justify-content-between align-items-center">
                                            Mot de passe
                                            <Link to="password-reset" style={{ color: "#ffffff", fontWeight: "bold" }}>Mot de passe oublié?</Link>
                                        </span>
                                    </div>
                                    <input {...register('password')} type="password" className="form-control form-control-lg" placeholder="***************" />
                                    {errors.password &&
                                        <div className="alert alert-danger sia-alert-danger closer">

                                            {errors.password?.message}
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="col-12 text-center mt-4">
                                <button type="submit" className="btn btn-lg btn-block btn-light lift text-uppercase" atl="signin">S'IDENTIFIER</button>
                            </div>
                            {/* <div className="col-12 text-center mt-4">
                                <span style={{ fontSize: "12px" }} className="text-xs">Vous n'avez pas encore de compte?&nbsp;&nbsp;<Link to="sign-up" style={{ color: "#ffffff", fontWeight: "bold" }}>Inscrivez-vous ici</Link></span>
                            </div> */}
                        </form>
                    </div>
                </div>
                <div className="col-lg-6 d-flex justify-content-center align-items-center border-0 rounded-lg" style={{ background: '#ffffff' }}>
                    <img src={TckLogo} alt="Logo TCK" />
                </div>
            </div>
        </div >

    )
}

export default SignIn;