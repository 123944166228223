import React from "react";

class AttendanceCard extends React.Component{
    render(){
        return(
            <div className="card mb-3">
                <div className="card-body">
                    <div className="atted-info d-flex mb-3 flex-wrap">
                            <div className="full-present me-2">
                                <i className="icofont-check-circled text-success me-1"></i>
                                <span>Full Day Present</span>
                            </div>
                            <div className="Half-day me-2">
                                <i className="icofont-wall-clock text-warning me-1"></i>
                                <span>Half Day Present</span>
                            </div>
                            <div className="absent me-2">
                                <i className="icofont-close-circled text-danger me-1"></i>
                                <span>Full Day Absence</span>
                            </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-hover align-middle mb-0" style={{width:'100%'}}>
                            <thead>
                                <tr>
                                    <th>Employee</th>
                                    <th>1</th>
                                    <th>2</th>
                                    <th>3</th>
                                    <th>4</th>
                                    <th>5</th>
                                    <th>6</th>
                                    <th>7</th>
                                    <th>8</th>
                                    <th>9</th>
                                    <th>10</th>
                                    <th>11</th>
                                    <th>12</th>
                                    <th>13</th>
                                    <th>14</th>
                                    <th>15</th>
                                    <th>16</th>
                                    <th>17</th>
                                    <th>18</th>
                                    <th>19</th>
                                    <th>20</th>
                                    <th>21</th>
                                    <th>22</th>
                                    <th>23</th>
                                    <th>24</th>
                                    <th>25</th>
                                    <th>27</th>
                                    <th>28</th>
                                    <th>29</th>
                                    <th>30</th>
                                    <th>31</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>
                                    <span className="fw-bold small">Joan Dyer</span>
                                </td>
                                <td>
                                            <i className="icofont-check-circled text-success"></i>
                                </td>
                                <td>
                                            <i className="icofont-check-circled text-success"></i>
                                </td>
                                <td>
                                            <i className="icofont-check-circled text-success"></i>
                                </td>
                                <td>
                                            <i className="icofont-wall-clock text-warning"></i>
                                </td>
                                <td>
                                            <i className="icofont-check-circled text-success"></i>
                                </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-wall-clock text-warning"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                </td>
                                <td>
                                            <i className="icofont-check-circled text-success"></i>
                                </td>
                                <td>
                                            <i className="icofont-check-circled text-success"></i>
                                </td>
                                <td>
                                            <i className="icofont-wall-clock text-warning"></i>
                                </td>
                                <td>
                                            <i className="icofont-check-circled text-success"></i>
                                </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    
                                </tr>
                                <tr>
                                    <td>
                                        <span className="fw-bold small">Ryan	Randall</span>
                                    </td>
                                    <td>
                                        <i className="icofont-close-circled text-danger"></i>
                                </td>
                                <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-close-circled text-danger"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-wall-clock text-warning"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-close-circled text-danger"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-wall-clock text-warning"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    
                                </tr>
                                <tr>
                                
                                    <td>
                                        <span className="fw-bold small">Phil	Glover</span>
                                    </td>
                                    <td>
                                        <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-wall-clock text-warning"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-wall-clock text-warning"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    
                                </tr>
                                <tr>
                                
                                    <td>
                                        <span className="fw-bold small">Victor Rampling</span>
                                    </td>
                                    <td>
                                        <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-wall-clock text-warning"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-wall-clock text-warning"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-wall-clock text-warning"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                
                                </tr>
                                <tr>
                                    
                                    <td>
                                        <span className="fw-bold small">Sally Graham</span>
                                    </td>
                                    <td>
                                        <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-wall-clock text-warning"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-wall-clock text-warning"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-wall-clock text-warning"></i>
                                    </td>
                                
                                </tr>
                                <tr>
                                
                                    <td>
                                        <span className="fw-bold small">Robert Anderson</span>
                                    </td>
                                    <td>
                                        <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                
                                </tr>
                                <tr>
                                    
                                    <td>
                                        <span className="fw-bold small">Ryan	Stewart</span>
                                    </td>
                                    <td>
                                        <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-check-circled text-success"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-wall-clock text-warning"></i>
                                    </td>
                                    <td>
                                            <i className="icofont-wall-clock text-warning"></i>
                                    </td>
                                
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default AttendanceCard;