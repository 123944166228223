export const ACTIONS = {
    AUTH: 'AUTH',
}

export const updateItem = (data, id, post, type) => {
    const newData = data.map(item => item.id === id ? post : item)
    // console.log('new', post, id)
    return ({ type, payload: newData })
}

export const updateItemImageCouverture = (data, id, post, type) => {
    const newData = data.id === id ? post : data
    // console.log('new', post, id)
    return ({ type, payload: newData })
}

export const deleteItem = (data, id, type) => {
    const newData = data.filter(item => item.id !== id)
    return ({ type, payload: newData })
}
