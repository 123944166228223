import React from "react";
import BadgesTile from "../../components/UI/BadgesTile";


class Badges extends React.Component {
  render(){
    return(
        <div className="container">
            <BadgesTile />
        </div>
    )
  }
}


export default Badges;