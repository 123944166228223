import api from "../Http/backend-base-url";

export const authenticate = {
  onLogin() {
    return `${api}login/`;
  },
  onRegesister() {
    return `${api}auth/register/`;
  },
  onMe() {
    return `${api}user/`;
  },
  onResetPassword() {
    return `${api}password/reset/`;
  },
};

export const user = {
  chagePassword() {
    return `${api}changepassword/`;
  },
};

export const property = {
  onPostProperties() {
    return `${api}listing/`;
  },
  onPutProperties(id) {
    return `${api}listing/${id}/`;
  },
  onDeleteProperties(id) {
    return `${api}listing/${id}/`;
  },
  onMiseAvant(id, type) {
    return `${api}miseenavant/${id}/${type}/`;
  },
};